import { Box, Stack, Typography } from "@mui/material";
import useLocales from "src/hooks/useLocales";
const Message = () => {
  const { translate } = useLocales();
  return (
    <div>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {translate("auth.signin")}
          </Typography>
        </Box>
      </Stack>
    </div>
  );
};

export default Message;
