import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Binary = Loadable(lazy(() => import("src/pages/genealogy/binary/index")));
const Sponsor = Loadable(
  lazy(() => import("src/pages/genealogy/sponsor/index"))
);
const Tree = Loadable(lazy(() => import("src/pages/genealogy/tree/index")));

const genealogy = [
  {
    path: "genealogy",
    children: [
      { element: <Navigate to="binary" />, index: true },
      { path: "binary", element: <Binary /> },
      { path: "sponsor", element: <Sponsor url="api/admin/sponsortree" /> },
      { path: "tree", element: <Tree /> },
    ],
  },
];

export default genealogy;
