export const countries = [
  { label: "Afghanistan", code: "AFG", "country-code": "004" },
  { label: "Åland Islands", code: "ALA", "country-code": "248" },
  { label: "Albania", code: "ALB", "country-code": "008" },
  { label: "Algeria", code: "DZA", "country-code": "012" },
  { label: "American Samoa", code: "ASM", "country-code": "016" },
  { label: "Andorra", code: "AND", "country-code": "020" },
  { label: "Angola", code: "AGO", "country-code": "024" },
  { label: "Anguilla", code: "AIA", "country-code": "660" },
  { label: "Antarctica", code: "ATA", "country-code": "010" },
  { label: "Antigua and Barbuda", code: "ATG", "country-code": "028" },
  { label: "Argentina", code: "ARG", "country-code": "032" },
  { label: "Armenia", code: "ARM", "country-code": "051" },
  { label: "Aruba", code: "ABW", "country-code": "533" },
  { label: "Australia", code: "AUS", "country-code": "036" },
  { label: "Austria", code: "AUT", "country-code": "040" },
  { label: "Azerbaijan", code: "AZE", "country-code": "031" },
  { label: "Bahamas", code: "BHS", "country-code": "044" },
  { label: "Bahrain", code: "BHR", "country-code": "048" },
  { label: "Bangladesh", code: "BGD", "country-code": "050" },
  { label: "Barbados", code: "BRB", "country-code": "052" },
  { label: "Belarus", code: "BLR", "country-code": "112" },
  { label: "Belgium", code: "BEL", "country-code": "056" },
  { label: "Belize", code: "BLZ", "country-code": "084" },
  { label: "Benin", code: "BEN", "country-code": "204" },
  { label: "Bermuda", code: "BMU", "country-code": "060" },
  { label: "Bhutan", code: "BTN", "country-code": "064" },
  {
    label: "Bolivia (Plurinational State of)",
    code: "BOL",
    "country-code": "068",
  },
  {
    label: "Bonaire, Sint Eustatius and Saba",
    code: "BES",
    "country-code": "535",
  },
  { label: "Bosnia and Herzegovina", code: "BIH", "country-code": "070" },
  { label: "Botswana", code: "BWA", "country-code": "072" },
  { label: "Bouvet Island", code: "BVT", "country-code": "074" },
  { label: "Brazil", code: "BRA", "country-code": "076" },
  {
    label: "British Indian Ocean Territory",
    code: "IOT",
    "country-code": "086",
  },
  { label: "Brunei Darussalam", code: "BRN", "country-code": "096" },
  { label: "Bulgaria", code: "BGR", "country-code": "100" },
  { label: "Burkina Faso", code: "BFA", "country-code": "854" },
  { label: "Burundi", code: "BDI", "country-code": "108" },
  { label: "Cabo Verde", code: "CPV", "country-code": "132" },
  { label: "Cambodia", code: "KHM", "country-code": "116" },
  { label: "Cameroon", code: "CMR", "country-code": "120" },
  { label: "Canada", code: "CAN", "country-code": "124" },
  { label: "Cayman Islands", code: "CYM", "country-code": "136" },
  { label: "Central African Republic", code: "CAF", "country-code": "140" },
  { label: "Chad", code: "TCD", "country-code": "148" },
  { label: "Chile", code: "CHL", "country-code": "152" },
  { label: "China", code: "CHN", "country-code": "156" },
  { label: "Christmas Island", code: "CXR", "country-code": "162" },
  { label: "Cocos (Keeling) Islands", code: "CCK", "country-code": "166" },
  { label: "Colombia", code: "COL", "country-code": "170" },
  { label: "Comoros", code: "COM", "country-code": "174" },
  { label: "Congo", code: "COG", "country-code": "178" },
  {
    label: "Congo, Democratic Republic of the",
    code: "COD",
    "country-code": "180",
  },
  { label: "Cook Islands", code: "COK", "country-code": "184" },
  { label: "Costa Rica", code: "CRI", "country-code": "188" },
  { label: "Côte d'Ivoire", code: "CIV", "country-code": "384" },
  { label: "Croatia", code: "HRV", "country-code": "191" },
  { label: "Cuba", code: "CUB", "country-code": "192" },
  { label: "Curaçao", code: "CUW", "country-code": "531" },
  { label: "Cyprus", code: "CYP", "country-code": "196" },
  { label: "Czechia", code: "CZE", "country-code": "203" },
  { label: "Denmark", code: "DNK", "country-code": "208" },
  { label: "Djibouti", code: "DJI", "country-code": "262" },
  { label: "Dominica", code: "DMA", "country-code": "212" },
  { label: "Dominican Republic", code: "DOM", "country-code": "214" },
  { label: "Ecuador", code: "ECU", "country-code": "218" },
  { label: "Egypt", code: "EGY", "country-code": "818" },
  { label: "El Salvador", code: "SLV", "country-code": "222" },
  { label: "Equatorial Guinea", code: "GNQ", "country-code": "226" },
  { label: "Eritrea", code: "ERI", "country-code": "232" },
  { label: "Estonia", code: "EST", "country-code": "233" },
  { label: "Eswatini", code: "SWZ", "country-code": "748" },
  { label: "Ethiopia", code: "ETH", "country-code": "231" },
  {
    label: "Falkland Islands (Malvinas)",
    code: "FLK",
    "country-code": "238",
  },
  { label: "Faroe Islands", code: "FRO", "country-code": "234" },
  { label: "Fiji", code: "FJI", "country-code": "242" },
  { label: "Finland", code: "FIN", "country-code": "246" },
  { label: "France", code: "FRA", "country-code": "250" },
  { label: "French Guiana", code: "GUF", "country-code": "254" },
  { label: "French Polynesia", code: "PYF", "country-code": "258" },
  {
    label: "French Southern Territories",
    code: "ATF",
    "country-code": "260",
  },
  { label: "Gabon", code: "GAB", "country-code": "266" },
  { label: "Gambia", code: "GMB", "country-code": "270" },
  { label: "Georgia", code: "GEO", "country-code": "268" },
  { label: "Germany", code: "DEU", "country-code": "276" },
  { label: "Ghana", code: "GHA", "country-code": "288" },
  { label: "Gibraltar", code: "GIB", "country-code": "292" },
  { label: "Greece", code: "GRC", "country-code": "300" },
  { label: "Greenland", code: "GRL", "country-code": "304" },
  { label: "Grenada", code: "GRD", "country-code": "308" },
  { label: "Guadeloupe", code: "GLP", "country-code": "312" },
  { label: "Guam", code: "GUM", "country-code": "316" },
  { label: "Guatemala", code: "GTM", "country-code": "320" },
  { label: "Guernsey", code: "GGY", "country-code": "831" },
  { label: "Guinea", code: "GIN", "country-code": "324" },
  { label: "Guinea-Bissau", code: "GNB", "country-code": "624" },
  { label: "Guyana", code: "GUY", "country-code": "328" },
  { label: "Haiti", code: "HTI", "country-code": "332" },
  {
    label: "Heard Island and McDonald Islands",
    code: "HMD",
    "country-code": "334",
  },
  { label: "Holy See", code: "VAT", "country-code": "336" },
  { label: "Honduras", code: "HND", "country-code": "340" },
  { label: "Hong Kong", code: "HKG", "country-code": "344" },
  { label: "Hungary", code: "HUN", "country-code": "348" },
  { label: "Iceland", code: "ISL", "country-code": "352" },
  { label: "India", code: "IND", "country-code": "356" },
  { label: "Indonesia", code: "IDN", "country-code": "360" },
  {
    label: "Iran (Islamic Republic of)",
    code: "IRN",
    "country-code": "364",
  },
  { label: "Iraq", code: "IRQ", "country-code": "368" },
  { label: "Ireland", code: "IRL", "country-code": "372" },
  { label: "Isle of Man", code: "IMN", "country-code": "833" },
  { label: "Israel", code: "ISR", "country-code": "376" },
  { label: "Italy", code: "ITA", "country-code": "380" },
  { label: "Jamaica", code: "JAM", "country-code": "388" },
  { label: "Japan", code: "JPN", "country-code": "392" },
  { label: "Jersey", code: "JEY", "country-code": "832" },
  { label: "Jordan", code: "JOR", "country-code": "400" },
  { label: "Kazakhstan", code: "KAZ", "country-code": "398" },
  { label: "Kenya", code: "KEN", "country-code": "404" },
  { label: "Kiribati", code: "KIR", "country-code": "296" },
  {
    label: "Korea (Democratic People's Republic of)",
    code: "PRK",
    "country-code": "408",
  },
  { label: "Korea, Republic of", code: "KOR", "country-code": "410" },
  { label: "Kuwait", code: "KWT", "country-code": "414" },
  { label: "Kyrgyzstan", code: "KGZ", "country-code": "417" },
  {
    label: "Lao People's Democratic Republic",
    code: "LAO",
    "country-code": "418",
  },
  { label: "Latvia", code: "LVA", "country-code": "428" },
  { label: "Lebanon", code: "LBN", "country-code": "422" },
  { label: "Lesotho", code: "LSO", "country-code": "426" },
  { label: "Liberia", code: "LBR", "country-code": "430" },
  { label: "Libya", code: "LBY", "country-code": "434" },
  { label: "Liechtenstein", code: "LIE", "country-code": "438" },
  { label: "Lithuania", code: "LTU", "country-code": "440" },
  { label: "Luxembourg", code: "LUX", "country-code": "442" },
  { label: "Macao", code: "MAC", "country-code": "446" },
  { label: "Madagascar", code: "MDG", "country-code": "450" },
  { label: "Malawi", code: "MWI", "country-code": "454" },
  { label: "Malaysia", code: "MYS", "country-code": "458" },
  { label: "Maldives", code: "MDV", "country-code": "462" },
  { label: "Mali", code: "MLI", "country-code": "466" },
  { label: "Malta", code: "MLT", "country-code": "470" },
  { label: "Marshall Islands", code: "MHL", "country-code": "584" },
  { label: "Martinique", code: "MTQ", "country-code": "474" },
  { label: "Mauritania", code: "MRT", "country-code": "478" },
  { label: "Mauritius", code: "MUS", "country-code": "480" },
  { label: "Mayotte", code: "MYT", "country-code": "175" },
  { label: "Mexico", code: "MEX", "country-code": "484" },
  {
    label: "Micronesia (Federated States of)",
    code: "FSM",
    "country-code": "583",
  },
  { label: "Moldova, Republic of", code: "MDA", "country-code": "498" },
  { label: "Monaco", code: "MCO", "country-code": "492" },
  { label: "Mongolia", code: "MNG", "country-code": "496" },
  { label: "Montenegro", code: "MNE", "country-code": "499" },
  { label: "Montserrat", code: "MSR", "country-code": "500" },
  { label: "Morocco", code: "MAR", "country-code": "504" },
  { label: "Mozambique", code: "MOZ", "country-code": "508" },
  { label: "Myanmar", code: "MMR", "country-code": "104" },
  { label: "Namibia", code: "NAM", "country-code": "516" },
  { label: "Nauru", code: "NRU", "country-code": "520" },
  { label: "Nepal", code: "NPL", "country-code": "524" },
  { label: "Netherlands", code: "NLD", "country-code": "528" },
  { label: "New Caledonia", code: "NCL", "country-code": "540" },
  { label: "New Zealand", code: "NZL", "country-code": "554" },
  { label: "Nicaragua", code: "NIC", "country-code": "558" },
  { label: "Niger", code: "NER", "country-code": "562" },
  { label: "Nigeria", code: "NGA", "country-code": "566" },
  { label: "Niue", code: "NIU", "country-code": "570" },
  { label: "Norfolk Island", code: "NFK", "country-code": "574" },
  { label: "North Macedonia", code: "MKD", "country-code": "807" },
  { label: "Northern Mariana Islands", code: "MNP", "country-code": "580" },
  { label: "Norway", code: "NOR", "country-code": "578" },
  { label: "Oman", code: "OMN", "country-code": "512" },
  { label: "Pakistan", code: "PAK", "country-code": "586" },
  { label: "Palau", code: "PLW", "country-code": "585" },
  { label: "Palestine, State of", code: "PSE", "country-code": "275" },
  { label: "Panama", code: "PAN", "country-code": "591" },
  { label: "Papua New Guinea", code: "PNG", "country-code": "598" },
  { label: "Paraguay", code: "PRY", "country-code": "600" },
  { label: "Peru", code: "PER", "country-code": "604" },
  { label: "Philippines", code: "PHL", "country-code": "608" },
  { label: "Pitcairn", code: "PCN", "country-code": "612" },
  { label: "Poland", code: "POL", "country-code": "616" },
  { label: "Portugal", code: "PRT", "country-code": "620" },
  { label: "Puerto Rico", code: "PRI", "country-code": "630" },
  { label: "Qatar", code: "QAT", "country-code": "634" },
  { label: "Réunion", code: "REU", "country-code": "638" },
  { label: "Romania", code: "ROU", "country-code": "642" },
  { label: "Russian Federation", code: "RUS", "country-code": "643" },
  { label: "Rwanda", code: "RWA", "country-code": "646" },
  { label: "Saint Barthélemy", code: "BLM", "country-code": "652" },
  {
    label: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SHN",
    "country-code": "654",
  },
  { label: "Saint Kitts and Nevis", code: "KNA", "country-code": "659" },
  { label: "Saint Lucia", code: "LCA", "country-code": "662" },
  {
    label: "Saint Martin (French part)",
    code: "MAF",
    "country-code": "663",
  },
  {
    label: "Saint Pierre and Miquelon",
    code: "SPM",
    "country-code": "666",
  },
  {
    label: "Saint Vincent and the Grenadines",
    code: "VCT",
    "country-code": "670",
  },
  { label: "Samoa", code: "WSM", "country-code": "882" },
  { label: "San Marino", code: "SMR", "country-code": "674" },
  { label: "Sao Tome and Principe", code: "STP", "country-code": "678" },
  { label: "Saudi Arabia", code: "SAU", "country-code": "682" },
  { label: "Senegal", code: "SEN", "country-code": "686" },
  { label: "Serbia", code: "SRB", "country-code": "688" },
  { label: "Seychelles", code: "SYC", "country-code": "690" },
  { label: "Sierra Leone", code: "SLE", "country-code": "694" },
  { label: "Singapore", code: "SGP", "country-code": "702" },
  {
    label: "Sint Maarten (Dutch part)",
    code: "SXM",
    "country-code": "534",
  },
  { label: "Slovakia", code: "SVK", "country-code": "703" },
  { label: "Slovenia", code: "SVN", "country-code": "705" },
  { label: "Solomon Islands", code: "SLB", "country-code": "090" },
  { label: "Somalia", code: "SOM", "country-code": "706" },
  { label: "South Africa", code: "ZAF", "country-code": "710" },
  {
    label: "South Georgia and the South Sandwich Islands",
    code: "SGS",
    "country-code": "239",
  },
  { label: "South Sudan", code: "SSD", "country-code": "728" },
  { label: "Spain", code: "ESP", "country-code": "724" },
  { label: "Sri Lanka", code: "LKA", "country-code": "144" },
  { label: "Sudan", code: "SDN", "country-code": "729" },
  { label: "Suriname", code: "SUR", "country-code": "740" },
  { label: "Svalbard and Jan Mayen", code: "SJM", "country-code": "744" },
  { label: "Sweden", code: "SWE", "country-code": "752" },
  { label: "Switzerland", code: "CHE", "country-code": "756" },
  { label: "Syrian Arab Republic", code: "SYR", "country-code": "760" },
  {
    label: "Taiwan, Province of China",
    code: "TWN",
    "country-code": "158",
  },
  { label: "Tajikistan", code: "TJK", "country-code": "762" },
  {
    label: "Tanzania, United Republic of",
    code: "TZA",
    "country-code": "834",
  },
  { label: "Thailand", code: "THA", "country-code": "764" },
  { label: "Timor-Leste", code: "TLS", "country-code": "626" },
  { label: "Togo", code: "TGO", "country-code": "768" },
  { label: "Tokelau", code: "TKL", "country-code": "772" },
  { label: "Tonga", code: "TON", "country-code": "776" },
  { label: "Trinidad and Tobago", code: "TTO", "country-code": "780" },
  { label: "Tunisia", code: "TUN", "country-code": "788" },
  { label: "Turkey", code: "TUR", "country-code": "792" },
  { label: "Turkmenistan", code: "TKM", "country-code": "795" },
  { label: "Turks and Caicos Islands", code: "TCA", "country-code": "796" },
  { label: "Tuvalu", code: "TUV", "country-code": "798" },
  { label: "Uganda", code: "UGA", "country-code": "800" },
  { label: "Ukraine", code: "UKR", "country-code": "804" },
  { label: "United Arab Emirates", code: "ARE", "country-code": "784" },
  {
    label: "United Kingdom of Great Britain and Northern Ireland",
    code: "GBR",
    "country-code": "826",
  },
  { label: "United States of America", code: "USA", "country-code": "840" },
  {
    label: "United States Minor Outlying Islands",
    code: "UMI",
    "country-code": "581",
  },
  { label: "Uruguay", code: "URY", "country-code": "858" },
  { label: "Uzbekistan", code: "UZB", "country-code": "860" },
  { label: "Vanuatu", code: "VUT", "country-code": "548" },
  {
    label: "Venezuela (Bolivarian Republic of)",
    code: "VEN",
    "country-code": "862",
  },
  { label: "Viet Nam", code: "VNM", "country-code": "704" },
  { label: "Virgin Islands (British)", code: "VGB", "country-code": "092" },
  { label: "Virgin Islands (U.S.)", code: "VIR", "country-code": "850" },
  { label: "Wallis and Futuna", code: "WLF", "country-code": "876" },
  { label: "Western Sahara", code: "ESH", "country-code": "732" },
  { label: "Yemen", code: "YEM", "country-code": "887" },
  { label: "Zambia", code: "ZMB", "country-code": "894" },
  { label: "Zimbabwe", code: "ZWE", "country-code": "716" },
];
