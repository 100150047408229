import { Box } from "@mui/material";
import Iconify from "src/components/Iconify";
import useLocales from "src/hooks/useLocales";
import useResponsive from "src/hooks/useResponsive";
import LanguagePopover from "src/layouts/shared/header/LanguagePopover";
const GetStartedTitle = () => {
  const smUp = useResponsive("up", "sm");
  const { translate } = useLocales();

  return (
    smUp && (
      <>
          <Box sx={{ mb: 2, textAlign: "right" }}>
            <LanguagePopover />
            <Iconify icon="material-symbols:chevron-right-rounded" />
          </Box>
        
      </>
    )
  );
};

export default GetStartedTitle;
