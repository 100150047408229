import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import {
  FormProvider,
  RHFCheckbox,
  RHFTextField,
} from "src/components/hook-form";
import useLocales from "src/hooks/useLocales";
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import { PATH_AUTH } from "src/routes/paths";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";

export default function LoginForm() {
  const { login, getUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const credentials = {
    user: {
      email: "irshad@bpract.com",
      password: "12345678",
      remember: true,
    },
    admin: {
      email: "info@cloudlumen.com",
      password: "12345678",
      remember: true,
    },
  };

  const defaultValues = {
    email: "",
    password: "",
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const {
      status,
      data: username,
      message,
    } = await login(data.email, data.password, data.remember);
    if (status) {
      getUser();
      enqueueSnackbar(`${translate("auth.welcomeOnly")} ${username}`);
      reset();
    } else {
      setError("afterSubmit", {
        message,
      });
    }
  };

  const theme = useTheme();

  const showButton = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {/* <Alert
          sx={{
            fontSize: "0.8rem",
          }}
          severity="info"
          onClick={() => {
            reset(credentials.admin);
          }}
          action={
            showButton && (
              <Typography>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    reset(credentials.admin);
                  }}
                >
                  USE
                </Button>
              </Typography>
            )
          }
        >
          <AlertText>
            Admin email:<b>{credentials.admin.email}</b> password:
            <b>{credentials.admin.password}</b>
          </AlertText>
        </Alert> */}

        {/* <Alert
          severity="info"
          sx={{
            fontSize: "0.8rem",
          }}
          onClick={() => {
            reset(credentials.user);
          }}
          action={
            showButton && (
              <Typography>
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    reset(credentials.user);
                  }}
                >
                  USE
                </Button>
              </Typography>
            )
          }
        >
          <AlertText>
            User email:<b>{credentials.user.email}</b> password:
            <b>{credentials.user.password}</b>
          </AlertText>
        </Alert> */}

        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <RHFCheckbox name="remember" label={translate("auth.remember")} />
        <Link
          component={RouterLink}
          variant="subtitle2"
          to={PATH_AUTH.resetPassword}
        >
          {translate("auth.forgot")} ?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {translate("auth.login")}
      </LoadingButton>
    </FormProvider>
  );
}

const AlertText = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="p"
      sx={{
        fontSize: ".7rem",
        "@media (max-width:380px)": {
          fontSize: ".49rem",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: ".7rem",
        },
      }}
    >
      {children}
    </Typography>
  );
};
