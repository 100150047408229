import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const BrandSettings = Loadable(lazy(() => import("src/pages/settings/brand")));
const BusinessSettings = Loadable(
  lazy(() => import("src/pages/settings/business/index"))
);
const NetworkSettings = Loadable(
  lazy(() => import("src/pages/settings/network/index"))
);

const Withdrawal = Loadable(
  lazy(() => import("src/pages/settings/withdrawal/index"))
);
const Vat = Loadable(lazy(() => import("src/pages/settings/vat/index")));
const PayoutSettings = Loadable(
  lazy(() => import("src/pages/settings/payout/index"))
);

const settings = [
  {
    path: "settings",
    children: [
      { element: <Navigate to="brand" />, index: true },
      { path: "brand", element: <BrandSettings /> },
      { path: "business", element: <BusinessSettings /> },
      { path: "network", element: <NetworkSettings /> },
      { path: "withdrawal", element: <Withdrawal /> },
      { path: "vat", element: <Vat /> },
      { path: "payout-settings", element: <PayoutSettings /> },
    ],
  },
];

export default settings;
