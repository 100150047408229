import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Registration = Loadable(lazy(() => import("src/pages/register")));

const registration = [
  {
    path: "registration",
    children: [
      { index: true, element: <Registration /> },
      // { path: "subscriptions_users", element: <SubscriptionsUsers /> },
    ],
  },
];

export default registration;
