import { PATH_USER } from "src/routes/paths";
import { getIconURI } from "../admin/NavConfig";

const ICONS = {
  blog: getIconURI("ic_blog"),
  cart: getIconURI("ic_cart"),
  chat: getIconURI("ic_chat"),
  mail: getIconURI("ic_mail"),
  user: getIconURI("ic_user"),
  kanban: getIconURI("ic_kanban"),
  banking: getIconURI("ic_banking"),
  booking: getIconURI("ic_booking"),
  invoice: getIconURI("ic_invoice"),
  calendar: getIconURI("ic_calendar"),
  ecommerce: getIconURI("ic_ecommerce"),
  analytics: getIconURI("ic_analytics"),
  dashboard: getIconURI("ic_dashboard"),
  setting: getIconURI("ic_settings"),
  online_store: getIconURI("ic_store"),
  my_subscription: getIconURI("ic_user_subscription"),
  business_builder: getIconURI("ic_bb"),
  profile: getIconURI("ic_profile"),
  help_center: getIconURI("ic_helpcenter"),
  report: getIconURI("ic_report"),
  eventIcon: getIconURI("ic_event"),
  ic_points: getIconURI("ic_points"),
  invoiceHistory: getIconURI("ic_invoiceHistory"),
};

/**
 * This build the navbar fo r the user side, each index will be a section on the side panel.
 * The object takes in the following keys:
 *  - subheader : for title of the section
 *  - items     : for taking in nav items
 *      - title : string
 *      - path  : link
 *      - icon  : icon
 *      - children: items[]
 */
const navConfig = [
  {
    items: [
      {
        title: "dashboard",
        path: PATH_USER.user_dashboard,
        icon: ICONS.dashboard,
      },

      // {
      //   title: "joinTelegram",
      //   path: "https://web.telegram.org/",
      //   icon: ICONS.chat,
      // },

      // {
      //   title: "onlineStore",
      //   path: PATH_USER.onlineStore.root,
      //   icon: ICONS.online_store,
      //   children: [
      //     {
      //       title: "product",
      //       path: PATH_USER.onlineStore.productSubscription.root,
      //     },
      //     { title: "myOrders", path: PATH_USER.my_orders.root },
      //   ],
      // },
      // {
      //   title: "mySubscriptions",
      //   icon: ICONS.my_subscription,
      //   path: PATH_USER.subscriptions.root,
      // },
      {
        title: "genealogy",
        icon: ICONS.kanban,
        path: PATH_USER.genealogy.root,
        children: [
          // { title: "binary", path: PATH_USER.genealogy.binary },
          { title: "Sponsor", path: PATH_USER.genealogy.sponsor },
          {
            title: "affiliateDashboard",
            path: PATH_USER.genealogy.affiliate,
          },
          // {
          //   title: "binaryLegSettings",
          //   path: PATH_USER.genealogy.binaryLeg,
          // },
        ],
      },
      {
        title: "events",
        icon: ICONS.eventIcon,
        path: PATH_USER.events.root,
      },
      {
        title: "financial",
        path: PATH_USER.financial.root,
        icon: ICONS.ecommerce,
        children: [
          {
            title: "myEWallet",
            path: PATH_USER.financial.eWallet,
          },
          // {
          //   title: "depositWallet",
          //   path: PATH_USER.financial.depositWallet,
          // },
          {
            title: "requestPayout",
            path: PATH_USER.financial.payout,
          },
          {
            title: "payoutHistory",
            path: PATH_USER.financial.payout_history,
          },
        ],
      },
      // {
      //   title: "businessBuilder",
      //   icon: ICONS.business_builder,
      //   path: PATH_USER.business_builder.root,
      //   children: [
      //     {
      //       title: "subscriptions",
      //       path: PATH_USER.business_builder.subscriptions,
      //     },
      //     {
      //       title: "materials",
      //       path: PATH_USER.business_builder.materials.root,
      //     },
      //   ],
      // },
      {
        title: "myProfile",
        icon: ICONS.profile,
        path: PATH_USER.profile.root,
      },
      {
        title: "blogs",
        icon: ICONS.blog,
        path: PATH_USER.blogs.root,
      },
      {
        title: "invoice",
        icon: ICONS.invoice,
        path: PATH_USER.invoiceUser.root,
      },
      {
        title: "invoice history",
        icon: ICONS.invoiceHistory,
        path: PATH_USER.invoiceHistory.root,
      },
      {
        title: "helpCenter",
        path: PATH_USER.helpCenter.root,
        icon: ICONS.help_center,
        children: [
          {
            title: "faqs",
            path: PATH_USER.helpCenter.faq.root,
          },
          {
            title: "knowledgeBase",
            path: PATH_USER.helpCenter.knowledgeBase.root,
          },
          {
            title: "emails",
            path: PATH_USER.helpCenter.mails.all,
          },
          {
            title: "createTicket",
            path: PATH_USER.helpCenter.createTicket.view,
          },
          {
            title: "videos",
            path: PATH_USER.helpCenter.videos,
          },
          {
            title: "documents",
            path: PATH_USER.helpCenter.documents,
          },
          {
            title: "article",
            path: PATH_USER.helpCenter.article,
          },
        ],
      },
      {
        title: "incomeReport",
        path: PATH_USER.incomeReport.root,
        icon: ICONS.report,
      },
      {
        title: "points",
        path: PATH_USER.points.root,
        icon: ICONS.ic_points,
      },
    ],
  },
];

export default navConfig;
