import DatePicker from "@mui/lab/DatePicker";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { capitalCase, snakeCase } from "change-case";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import useLocales from "src/hooks/useLocales";

import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import i18n from "src/locales/i18n";
import convertDMYToMDY from "src/utils/convertDMYToMDY";
import Users from "src/components/users";
i18n.t("userFinancial.eWallet.no");

const FilterCard = ({ methods, onFilter }) => {
  // const options = [
  //   i18n.t("userFinancial.eWallet.all"),
  //   i18n.t("userFinancial.eWallet.released"),
  //   i18n.t("userFinancial.eWallet.pending"),
  //   i18n.t("userFinancial.eWallet.failed"),
  //   i18n.t("userFinancial.eWallet.rejected"),
  //   i18n.t("userFinancial.eWallet.finished"),
  //   i18n.t("userFinancial.eWallet.approved"),
  //   i18n.t("userFinancial.eWallet.fund_transfer"),
  //   i18n.t("userFinancial.eWallet.plan_purchase"),
  //   i18n.t("userFinancial.eWallet.fund_transfer"),
  //   i18n.t("userFinancial.eWallet.self_transfer"),
  //   i18n.t("userFinancial.eWallet.referral_bonus"),
  //   i18n.t("userFinancial.eWallet.achievement_bonus"),
  //   i18n.t("userFinancial.eWallet.first_order_bonus"),
  //   i18n.t("userFinancial.eWallet.binary_bonus"),
  //   i18n.t("userFinancial.eWallet.credited_by_admin"),
  //   i18n.t("userFinancial.eWallet.deducted_by_admin"),
  // ];
  const options = [
    i18n.t("userFinancial.eWallet.directReferralBonus"),
    i18n.t("userFinancial.eWallet.indirectReferralBonus"),
    i18n.t("userFinancial.eWallet.creditedByAdmin"),
  ];

  const { translate } = useLocales();

  // const methods = useForm();
  // const onSubmit = methods.handleSubmit(() => null);
  const { control } = methods;
  return (
    <div>
      <Card sx={{ p: 3 }}>
        <Box>
          <Typography variant="subtitle2">
            {translate("userFinancial.eWallet.walletHistory")}
          </Typography>
          <FormProvider methods={methods} onSubmit={onFilter}>
            <Box
              sx={{
                display: "grid",
                rowGap: 3,
                columnGap: 2,
                marginTop: 3,
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(5, 1fr)",
                },
              }}
            >
              <Controller
                control={control}
                name="start_date"
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label="Pick Start Date"
                    inputFormat="dd/MM/yyyy"
                    value={new Date(convertDMYToMDY(field.value))}
                    onChange={(newValue) => {
                      if (newValue) {
                        field.onChange(
                          new Date(newValue).toLocaleDateString("en-GB")
                        );
                      } else {
                        field.onChange("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name="end_date"
               
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                  inputFormat="dd/MM/yyyy"
                    label={translate("userFinancial.eWallet.pickEndDate")}
                    value={new Date(convertDMYToMDY(field.value))}
                    onChange={(newValue) => {
                      if (newValue) {
                        field.onChange(
                          new Date(newValue).toLocaleDateString("en-GB")
                        );
                      } else {
                        field.onChange("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />

              
              <Users
                label={translate("adminFinancial.Ewallet.userName")}
                name="user_id"
              />
             
              <RHFSelect
                name="payment_type"
                label={translate("userFinancial.eWallet.amountType")}
              >
                <option></option>
                {options.map((option) => (
                  <option value={snakeCase(option)}>
                    {capitalCase(option)}
                  </option>
                ))}
              </RHFSelect>
              <RHFSelect
                name="rowsPage"
                label={translate("userFinancial.eWallet.numberOfRows")}
              >
                <option></option>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
              </RHFSelect>
            </Box>
            <Box mt={2} sx={{ float: "right" }}>
              <Button variant="contained" type="submit">
                {translate("userFinancial.eWallet.getReport")}
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Card>
    </div>
  );
};

export default FilterCard;
