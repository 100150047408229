import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Approval = Loadable(lazy(() => import("src/pages/approval")));

const approval = [
  {
    path: "approval",
    children: [{ index: true, element: <Approval /> }],
  },
];

export default approval;
