import { Box, Typography } from "@mui/material";
import React from "react";
import EmptyContent from "src/components/EmptyContent";
import useLocales from "src/hooks/useLocales";
const EmptyTable = () => {
  const { translate } = useLocales();
  return (
    <Box>
      <EmptyContent
        title={<Typography variant="subtitle2">{translate("latest.noDataAvailable")}</Typography>}
        sx={{
          "& span.MuiBox-root": { height: 160 },
          color: "#b2b2b2",
        }}
      />
    </Box>
  );
};

export default EmptyTable;
