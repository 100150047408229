import { Grid } from "@mui/material";
import Cards from "./cards";
import i18n from "src/locales/i18n";
import Total from "src/pages/dashboard/network/components/total";

const Summary = ({ summary }) => {
  const summaryCard = [
    {
      title: "userFinancial.eWallet.balance ",
      icon: "arcticons:priceconverter",
      color: "#d279a6",
      key: "balance",
    },
    {
      title: "userFinancial.eWallet.transferOut",
      icon: "bx:transfer-alt",
      color: "#6699ff",
      key: "transfer_out",
    },
    {
      title: "userFinancial.eWallet.totalPayout",
      icon: "fluent:wallet-48-regular",
      color: "#993366",
      key: "total_payout",
    },
    {
      title: "userFinancial.eWallet.totalEarnedBonus",
      icon: "carbon:ibm-cloud-pak-network-automation",
      color: "#999966",
      key: "total_earned_bonus",
    },
    {
      title: "latest.totalPoint",
      icon: "carbon:carbon-for-ibm-product",
      color: "#b57f88",
      key: "point",
    },
    {
      title: "latest.totalRevertBonus",
      icon: "grommet-icons:revert",
      color: "#b57f88",
      key: "total_revert_bonus",
    },
  ];
  return (
    <>
      {summaryCard.map((props) => (
        <Grid item xs={12} md={4}>
          <Cards {...props} total={summary[props.key]} />
        </Grid>
      ))}
    </>
  );
};

export default Summary;
