import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const InvoiceNew = Loadable(lazy(() => import("src/pages/invoice")));
const InvoiceDetails = Loadable(
  lazy(() => import("src/pages/invoice/invoices/invoiceDetails"))
);

const invoicenew = [
  {
    path: "invoicenew",
    children: [
      { index: true, element: <InvoiceNew /> },
      {
        path: ":id",
        element: <InvoiceDetails />,
      },
    ],
  },
];

export default invoicenew;
