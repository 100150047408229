import { Box, Grid } from "@mui/material";
import HeaderBreadcrumbs from "src/components/HeaderBreadcrumbs";
import Page from "src/components/Page";
import { PATH_USER } from "src/routes/paths";
import useFetchSummary from "../hooks/useFetchSummary";
import useFetchWitPagination from "../hooks/useFetchWithPagination";
import { DataList, FilterCard } from "./components";
import Summary from "./components/summary";
import useLocales from "src/hooks/useLocales";
import { useForm } from "react-hook-form";
const defaultValues = {
  start_date: "",
  end_date: "",
  userName: null,
};
const Index = () => {
  const methods = useForm({ defaultValues });
  const filter = methods.watch();

  const { translate } = useLocales();
  const { data, fetchData, ...rest } = useFetchWitPagination(filter, "ewallet");
  const summary = useFetchSummary("ewallet-data");
  const onFilter = methods.handleSubmit((inputData) => {
    fetchData(1, inputData);
  });
  return (
    <div>
      <Page title={translate("userFinancial.eWallet.ewalletTitile")}>
        <Box sx={{ p: 2 }}>
          <HeaderBreadcrumbs
            heading={translate("userFinancial.eWallet.myEwallet")}
            links={[
              { name: translate("dashboard"), href: PATH_USER.root },
              { name: translate("userFinancial.eWallet.myEwallet") },
            ]}
          />

          <Grid container spacing={3}>
            <Summary summary={summary} />
            <Grid item xs={12} md={12}>
              <FilterCard methods={methods} onFilter={onFilter} />
            </Grid>
            <Grid item xs={12} md={12}>
              <DataList data={data} {...rest} />
            </Grid>
          </Grid>
        </Box>
      </Page>
    </div>
  );
};

export default Index;
