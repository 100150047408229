import { lazy } from "react";
import Loadable from "src/routes/Loadable";
import approval from "./approval";
import communication from "./communication";
import dashboard from "./dashboard";
import financial from "./financial";
import genealogy from "./genealogy";
import invoice from "./invoice";
import invoicenew from "./invoicenew";
import members from "./members";
import registration from "./registration";
import reports from "./reports";
import settings from "./settings";
import statistics from "./statistics";
import store from "./store";
import subAdmin from "./subAdmin";
import subscriptions from "./subscriptions";
import tools from "./tools";
import user from "./user";
import event from "./event";
const ManualCommission = Loadable(
  lazy(() => import("src/pages/manualCommission/index"))
);

const ManualRankUpdate = Loadable(
  lazy(() => import("src/pages/manualRankUpdate/index"))
);
const RevertCommission = Loadable(
  lazy(() => import("src/pages/commissionRevert/index"))
);
const RevertPoint = Loadable(
  lazy(() => import("src/pages/revertPoint/index"))
);
const ApprovedRegistrations = Loadable(
  lazy(() => import("src/pages/approvedRegistrations/index"))
);

const routes = [
  ...communication,
  ...dashboard,
  ...financial,
  ...genealogy,
  ...invoice,
  ...members,
  ...reports,
  ...settings,
  ...statistics,
  ...invoicenew,
  ...store,
  ...subAdmin,
  ...subscriptions,
  ...tools,
  ...user,
  ...registration,
  ...approval,
  ...event,

  {
    path: "manual-commissions",
    element: <ManualCommission />,
  },
  {
    path: "commission-revert",
    element: <RevertCommission />,
  },
  {
    path: "revert-manual-commissions",
    element: <RevertPoint />,
  },
  {
    path: "manual-rank-update",
    element: <ManualRankUpdate />,
  },
  {path:"approved-registrations" , element:<ApprovedRegistrations/>}
];

export default routes;
