import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Events = Loadable(lazy(() => import("src/pages/store/events/index")));
const EventsAdd = Loadable(
  lazy(() => import("src/pages/store/events/eventAdd"))
);
const EventsEdit = Loadable(
  lazy(() => import("src/pages/store/events/eventEdit"))
);
const event = [
  {
    path: "events",
    children: [
      { index: true, element: <Events /> },
      { path: "add", element: <EventsAdd /> },
      { path: ":eid", element: <EventsEdit /> },
    ],
  },
];

export default event;
