import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TotalWidget from "../widgets/TotalWidget";
import useTotal from "./useTotal";
import useLocales from "src/hooks/useLocales";
import { fNumber, fPercent } from "src/utils/formatNumber";
import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import Iconify from "src/components/Iconify";
import { alpha } from "@mui/material/styles";
const Total = () => {
  const { translate } = useLocales();
  const theme = useTheme();
  const {
    network_bonus_graph,
    network_growthRate,
    total_network_bonus,
    payout_graph,
    total_payout,
    payout_growth,
    total_points,
    total_revert_points,
  } = useTotal();

  return (
    <>
      <Grid item xs={12} md={6}>
        <TotalWidget
          title={translate("adminDashboard.network.totalNetworkBonus")}
          percent={network_growthRate}
          total={total_network_bonus}
          chartColor={theme.palette.primary.main}
          chartData={network_bonus_graph}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TotalWidget
          title={translate("adminDashboard.network.totalPayout")}
          percent={payout_growth}
          total={total_payout}
          chartColor={theme.palette.chart.green[0]}
          chartData={payout_graph}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PointCard
          title={translate("latest.totalPoints")}
          total={total_points}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <PointCard
          title={translate("latest.totalRevertPoints")}
          total={total_revert_points}
        />
      </Grid>
    </>
  );
};
const PointCard = ({ title, percent, total }) => {
  return (
    <Card sx={{ display: "flex", alignItems: "center", p: 3 }}>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2" paragraph>
          {title}
        </Typography>
        <Typography variant="h3" gutterBottom>
          {fNumber(total)}
        </Typography>
      </Box>
    </Card>
  );
};
export default Total;
