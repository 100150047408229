import { lazy } from "react";
import { Navigate } from "react-router";
import mails from "src/routes/admin/routes/communication/main";
import Loadable from "../Loadable";

const Mail = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/mail/index"))
);

const Faq = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/faq/index"))
);

const KnowledgeBase = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/knowledgeBase/index"))
);
const Questions = Loadable(
  lazy(() =>
    import("src/pages/userSide/helpCenter/knowledgeBase/question/index")
  )
);

const HelpCenterTicket = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/ticket/index"))
);

const ContactSupport = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/ticket/contact/index"))
);
const MySupportTicket = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/ticket/mysupport/index"))
);
const OtherSupport = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/ticket/others/index"))
);

const Tickets = Loadable(
  lazy(() => import("src/pages/userSide/helpCenter/materialTickets/index"))
);

const TicketView = Loadable(
  lazy(() =>
    import("src/pages/userSide/helpCenter/ticket/mysupport/viewTickets")
  )
);
const Video = Loadable(
  lazy(() =>
    import("src/pages/userSide/helpCenter/video/index")
  )
);

const Document = Loadable(
  lazy(() =>
    import("src/pages/userSide/helpCenter/DowloadDoc/Index")
  )
);
const Article = Loadable(
  lazy(() =>
    import("src/pages/userSide/helpCenter/article/index")
  )
);

// const Emails = Loadable(
//   lazy(() => import("src/routes/dashboard/communication/main"))
// );

const helpCenter = {
  path: "help-center",
  children: [
    { index: true, element: <Navigate to="faqs" /> },
    {
      path: "faqs",
      children: [
        { index: true, element: <Navigate to="product-services" /> },
        {
          path: ":label",
          element: <Faq />,
        },
      ],
    },
    {
      path: "knowledge-base",
      children: [
        {
          index: true,
          element: <KnowledgeBase />,
        },
        {
          path: ":slug",
          element: <Questions />,
        },
      ],
    },
    { ...mails },
    // {
    //   path: "emails",
    //   children: [
    //     { element: <Navigate to="all" replace />, index: true },
    //     { path: "label/:customLabel", element: <Mail /> },
    //     { path: "label/:customLabel/:mailId", element: <Mail /> },
    //     { path: ":systemLabel", element: <Mail /> },
    //     { path: ":systemLabel/:mailId", element: <Mail /> },
    //   ],
    // },
    {
      path: "create-ticket",
      children: [
        {
          index: true,
          element: <HelpCenterTicket />,
        },

        {
          path: ":label",
          children: [
            { index: true, element: <Tickets /> },
            { path: ":id", element: <TicketView /> },
          ],
        },

        {
          path: "contact-support",
          element: <ContactSupport />,
        },
        {
          path: "my-support-ticket",
          element: <MySupportTicket />,
        },
        {
          path: "other-support",
          element: <OtherSupport />,
        },
       
      ],
    },
    {
      path: "videos",
      element: <Video />,
    },
    {
      path: "documents",
      element: <Document />,
    },
    {
      path: "article",
      element: <Article />,
    },
  ],
};

export default helpCenter;
