import { PATH_DASHBOARD } from "src/routes/paths";
export const getIconURI = (name) => `/icons/${name}.svg`;

const ICONS = {
  blog: getIconURI("ic_blog"),
  cart: getIconURI("ic_cart"),
  chat: getIconURI("ic_chat"),
  mail: getIconURI("ic_mail"),
  user: getIconURI("ic_user"),
  kanban: getIconURI("ic_kanban"),
  banking: getIconURI("ic_banking"),
  booking: getIconURI("ic_booking"),
  invoice: getIconURI("ic_invoice"),
  calendar: getIconURI("ic_calendar"),
  ecommerce: getIconURI("ic_ecommerce"),
  analytics: getIconURI("ic_analytics"),
  dashboard: getIconURI("ic_dashboard"),
  settings: getIconURI("ic_settings"),
  tools: getIconURI("ic_tools"),
  member_management: getIconURI("ic_member_management"),
  subadmin: getIconURI("ic_subadmin"),
  holdingtank: getIconURI("ic_holdingtank"),
  store: getIconURI("ic_store"),
  user_subscription: getIconURI("ic_user_subscription"),
  report: getIconURI("ic_report"),
  register: getIconURI("ic_register"),
  approved: getIconURI("ic_approved"),
  registered: getIconURI("ic_registered"),
  manualCommissions: getIconURI("ic_mail"),
  manualRankUpdate: getIconURI("ic_holdingtank"),
  eventIcon: getIconURI("ic_event"),
  revertCommission: getIconURI("ic_revertCommission"),
  manualPoints: getIconURI("ic_manualPoints"),
  
};

const navConfig = [
  {
    items: [
      {
        title: "dashboard",
        path: PATH_DASHBOARD.dashboard.root,
        icon: ICONS.dashboard,
        children: [
          {
            title: "business",
            path: PATH_DASHBOARD.dashboard.business,
          },
          {
            title: "network",
            path: PATH_DASHBOARD.dashboard.network,
          },
        ],
      },

      {
        title: "genealogy",
        path: PATH_DASHBOARD.genealogy.root,
        icon: ICONS.kanban,
        children: [
          // {
          //   title: "binary",
          //   path: PATH_DASHBOARD.genealogy.binary,
          // },
          {
            title: "sponsor",
            path: PATH_DASHBOARD.genealogy.sponsor,
          },
          { title: "tree", path: PATH_DASHBOARD.genealogy.tree },
        ],
      },
      {
        title: "registration",
        icon: ICONS.register,
        path: PATH_DASHBOARD.registration.root,
      },
      {
        title: "registrationApproval",
        icon: ICONS.approved,
        path: PATH_DASHBOARD.approval.root,
      },
      {
        title: "approvedRegistrations",
        icon: ICONS.registered,
        path: PATH_DASHBOARD.approved_registration.root,
      },
      {
        title: "events",
        icon: ICONS.eventIcon,
        path: PATH_DASHBOARD.event.root,
      },
      {
        title: "financial",
        path: PATH_DASHBOARD.financial.root,
        icon: ICONS.ecommerce,
        children: [
          {
            title: "ewallet",
            path: PATH_DASHBOARD.financial.ewallet,
          },
          // {
          //   title: "depositWallet",
          //   path: PATH_DASHBOARD.financial.deposit_wallet,
          // },
          {
            title: "pointCreditHistory",
            path: PATH_DASHBOARD.financial.point_history,
          },
          {
            title: "pointRevertHistory",
            path: PATH_DASHBOARD.financial.point_revert,
          },
          {
            title: "fundCredits",
            path: PATH_DASHBOARD.financial.fund_credits,
          },
          {
            title: "payout",
            path: PATH_DASHBOARD.financial.payout,
          },
        ],
      },
      {
        title: "communication",
        path: PATH_DASHBOARD.communication.root,
        icon: ICONS.chat,
        children: [
          {
            title: "blog",
            path: PATH_DASHBOARD.communication.blog,
          },
          {
            title: "faqs",
            path: PATH_DASHBOARD.communication.com_faqs,
          },
          {
            title: "emails",
            path: PATH_DASHBOARD.communication.mails,
          },
          {
            title: "helpCenterr",
            path: PATH_DASHBOARD.communication.help_center,
          },
          {
            title: "article",
            path: PATH_DASHBOARD.communication.com_article,
          },
        ],
      },

      {
        title: "tools",
        path: PATH_DASHBOARD.tools.root,
        icon: ICONS.tools,
        children: [
          {
            title: "documents",
            path: PATH_DASHBOARD.tools.documents,
          },
          { title: "videos", path: PATH_DASHBOARD.tools.videos },
        ],
      },
      {
        title: "membersManagement",
        path: PATH_DASHBOARD.members.root,
        icon: ICONS.member_management,
        children: [
          {
            title: "networkMembers",
            path: PATH_DASHBOARD.members.network,
          },
          // {
          //   title: "holdingTank",
          //   path: PATH_DASHBOARD.members.holdingTank,
          // },
        ],
      },

      // {
      //   title: "subAdmin",
      //   path: PATH_DASHBOARD.subAdmin.root,
      //   icon: ICONS.subadmin,
      //   children: [
      //     {
      //       title: "subAdmins",
      //       path: PATH_DASHBOARD.subAdmin.sub_admins,
      //     },
      //   ],
      // },

      // {
      //   title: "store",
      //   path: PATH_DASHBOARD.store.root,
      //   icon: ICONS.store,
      //   children: [
      //     {
      //       title: "products",
      //       path: PATH_DASHBOARD.store.products,
      //     },
      //     {
      //       title: "material",
      //       path: PATH_DASHBOARD.store.material,
      //     },
      //     { title: "events", path: PATH_DASHBOARD.store.events },
      //     {
      //       title: "Coupons",
      //       path: PATH_DASHBOARD.store.coupons,
      //     },
      //     {
      //       title: "userReviews",
      //       path: PATH_DASHBOARD.store.user_reviews,
      //     },

      //     {
      //       title: "assignSubscriptions",
      //       path: PATH_DASHBOARD.store.assign_subscriptions,
      //     },
      //     {
      //       title: "businessBuilderSubscriptionsSales",
      //       path: PATH_DASHBOARD.store.business_builder_subscriptions,
      //     },
      //   ],
      // },

      // {
      //   title: "userSubscriptions",
      //   icon: ICONS.user_subscription,
      //   path: PATH_DASHBOARD.activeSubscriptions.root,
      // },
      // {
      //   title: "statistics",
      //   icon: ICONS.analytics,
      //   path: PATH_DASHBOARD.statistics.root,
      // },
      {
        title: "manualCommissions",
        icon: ICONS.manualCommissions,
        path: PATH_DASHBOARD.manualCommissions.root,
      },
      {
        title: "latest.commissionRevert",
        icon: ICONS.revertCommission,
        
        path: PATH_DASHBOARD.commissionRevert.root,
      },
      {
        title: "latest.revertManualPoint",
        icon: ICONS.manualPoints,
        path: PATH_DASHBOARD.revertManualCommissions.root,
      },
      {
        title: "manualRankUpdate",
        icon: ICONS.manualRankUpdate,
        path: PATH_DASHBOARD.manualRankUpdate.root,
      },
      // {
      //   title: "invoice",
      //   icon: ICONS.invoice,
      //   path: PATH_DASHBOARD.invoicenew.root,
      // },
    ],
  },

  {
    subheader: "general",
    items: [
      {
        title: "settings",
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        children: [
          {
            title: "brand",
            path: PATH_DASHBOARD.settings.brand,
          },
          // {
          //   title: "businessBuilder",
          //   path: PATH_DASHBOARD.settings.business,
          // },
          {
            title: "network",
            path: PATH_DASHBOARD.settings.network,
          },
          // {
          //   title: "Withdrawal",
          //   path: PATH_DASHBOARD.settings.withdrawal,
          // },
          {
            title: "Vat",
            path: PATH_DASHBOARD.settings.vat,
          },
          {
            title: "payoutSettings",
            path: PATH_DASHBOARD.settings.payout,
          },
        ],
      },
      {
        title: "reports",
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.report,
        children: [
          // {
          //   title: "builderSubscription",
          //   path: PATH_DASHBOARD.report.builder,
          // },
          {
            title: "fundCredit",
            path: PATH_DASHBOARD.report.fund_credit,
          },
          {
            title: "joiningReport",
            path: PATH_DASHBOARD.report.joining,
          },
          {
            title: "memberIncome",
            path: PATH_DASHBOARD.report.member_income,
          },
          {
            title: "payout",
            path: PATH_DASHBOARD.report.payout_report,
          },
          // {
          //   title: "pointHistory",
          //   path: PATH_DASHBOARD.report.point_history,
          // },
          // {
          //   title: "sales",
          //   path: PATH_DASHBOARD.report.sales_report,
          // },
          {
            title: "topEarners",
            path: PATH_DASHBOARD.report.top_earners,
          },
          {
            title: "manualRankUpdate",
            path: PATH_DASHBOARD.report.manual_rank_update,
          },
          {
            title: "invoiceReport",
            path: PATH_DASHBOARD.report.invoice_report,
          },
        ],
      },
    ],
  },
];
export default navConfig;
